const Procedures = () => import(/* webpackChunkName: 'Procedures' */'@/views/procedures/ViewProcedures')
const NewProcedure = () => import(/* webpackChunkName: 'NewProcedure' */'@/views/procedures/ViewNewProcedure')
const Procedure = () => import(/* webpackChunkName: 'Procedure' */'@/views/procedures/ViewProcedure')
const ProcedureProducts = () => import(/* webpackChunkName: 'ProcedureProducts' */'@/views/procedures/ViewProcedureProducts')
const ProcedureEmails = () => import(/* webpackChunkName: 'ProcedureEmails' */'@/views/procedures/ViewProcedureEmails')
const ProcedureStep = () => import(/* webpackChunkName: 'ProcedureStep' */'@/views/procedures/ViewProcedureStep')
const ProcedureNewSection = () => import(/* webpackChunkName: 'ProcedureNewSection' */'@/views/procedures/ViewProcedureNewSection')
const ProcedureNewBlockChoice = () => import(/* webpackChunkName: 'ProcedureNewBlockChoice' */'@/views/procedures/ViewProcedureNewBlockChoice')
const ProcedureBlock = () => import(/* webpackChunkName: 'ViewProcedureBlock' */'@/views/procedures/ViewProcedureBlock')
const ProcedureCustomField = () => import(/* webpackChunkName: 'ViewProcedureCustomField' */'@/views/procedures/ViewProcedureCustomField')

const routesClient = [
  {
    path: 'procedures',
    name: 'ClientProcedures',
    component: Procedures,
    meta: {
      requiredAuth: true,
      requiredFeatures: ['donation_forms'],
      breadcrumb: [
        { label: 'literal.forms', routeName: 'ClientProcedures' }
      ],
      navigation: {
        newProcedure: 'ClientNewProcedure',
        settings: 'ClientProcedureSettings',
        steps: 'ClientProcedure'
      },
      faq: ['procedures_how_to_get_started']
    }
  },
  {
    path: 'procedures/new',
    name: 'ClientNewProcedure',
    component: NewProcedure,
    meta: {
      requiredAuth: true,
      requiredFeatures: ['donation_forms'],
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.forms', routeName: 'ClientProcedures' },
        { label: 'literal.new_form', routeName: 'ClientNewProcedure' }
      ],
      navigation: {
        back: 'ClientProcedures'
      }
    }
  },
  {
    path: 'procedures/:procedureSlug',
    name: 'ClientProcedure',
    component: Procedure,
    props: true,
    meta: {
      requiredAuth: true,
      requiredFeatures: ['donation_forms'],
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.forms', routeName: 'ClientProcedures' },
        { slot: 'Procedure', routeName: 'ClientProcedure' }
      ],
      navigation: {
        back: 'ClientProcedures',
        step: 'ClientProcedureStep',
        settingsTitle: 'ClientProcedureSettingsTitle',
        settingsStatus: 'ClientProcedureSettingsStatus',
        settingsLinks: 'ClientProcedureSettingsLinks',
        settingsPayments: 'ClientProcedureSettingsPayments',
        settingsPayconiq: 'ClientProcedureSettingsPayconiq',
        settingsRedirection: 'ClientProcedureSettingsRedirection'
      },
      faq: ['procedures_how_can_i_build_a_form']
    }
  },
  {
    path: 'procedures/:procedureSlug/emails',
    name: 'ClientProcedureEmails',
    component: ProcedureEmails,
    props: true,
    meta: {
      requiredAuth: true,
      requiredFeatures: ['donation_forms'],
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.forms', routeName: 'ClientProcedures' },
        { slot: 'Procedure', routeName: 'ClientProcedure' },
        { label: 'literal.emails', routeName: 'ClientProcedureEmails' }
      ],
      navigation: {
        email: 'ClientProcedureEditEmail'
      },
      faq: [
        'procedures_what_is_the_email_section_and_how_to_start',
        'procedures_how_can_i_modify_the_confirmation_email_of_the_donation_contribution',
        'emails_set_up_transactional_mails',
        'emails_set_up_activation_mails',
        'emails_can_i_change_the_sender_address_of_the_automatic_mails'
      ]
    }
  },
  {
    path: 'procedures/:procedureSlug/products',
    name: 'ClientProcedureProducts',
    component: ProcedureProducts,
    props: true,
    meta: {
      requiredAuth: true,
      requiredFeatures: ['donation_forms', 'products'],
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.forms', routeName: 'ClientProcedures' },
        { slot: 'Procedure', routeName: 'ClientProcedure' },
        { label: 'literal.products', routeName: 'ClientProcedureProducts' }
      ],
      navigation: {
        product: 'ClientProcedureEditProduct'
      },
      faq: [
        'procedures_what_are_products',
        'procedures_how_can_i_use_products_in_my_forms'
      ]
    }
  },
  {
    path: 'procedures/:procedureSlug/:stepSlug',
    name: 'ClientProcedureStep',
    component: ProcedureStep,
    props: true,
    meta: {
      requiredAuth: true,
      requiredFeatures: ['donation_forms'],
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.forms', routeName: 'ClientProcedures' },
        { slot: 'Procedure', routeName: 'ClientProcedure' },
        { slot: 'ProcedureStep', routeName: 'ClientProcedureStep' }
      ],
      navigation: {
        back: 'ClientProcedure',
        editStep: 'ClientProcedureEditStep',
        newSection: 'ClientProcedureNewSection',
        editBlock: 'ClientProcedureEditBlock',
        blockChoice: 'ClientProcedureNewBlockChoice'
      },
      faq: ['procedures_how_can_i_build_a_form']
    }
  },
  {
    path: 'procedures/:procedureSlug/:stepSlug/new-section',
    name: 'ClientProcedureNewSection',
    component: ProcedureNewSection,
    props: true,
    meta: {
      requiredAuth: true,
      requiredFeatures: ['donation_forms'],
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.forms', routeName: 'ClientProcedures' },
        { slot: 'Procedure', routeName: 'ClientProcedure' },
        { slot: 'ProcedureStep', routeName: 'ClientProcedureStep' },
        { label: 'literal.new_section', routeName: 'ClientProcedureNewSection' }
      ],
      navigation: {
        back: 'ClientProcedureStep'
      }
    }
  },
  {
    path: 'procedures/:procedureSlug/:stepSlug/:sectionSlug/new-element',
    name: 'ClientProcedureNewBlockChoice',
    component: ProcedureNewBlockChoice,
    props: true,
    meta: {
      requiredAuth: true,
      requiredFeatures: ['donation_forms'],
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.forms', routeName: 'ClientProcedures' },
        { slot: 'Procedure', routeName: 'ClientProcedure' },
        { slot: 'ProcedureStep', routeName: 'ClientProcedureStep' },
        { label: 'literal.add_an_element', routeName: 'ClientProcedureNewBlockChoice' }
      ],
      navigation: {
        back: 'ClientProcedureStep',
        newBlock: 'ClientProcedureNewBlock',
        newCustomField: 'ClientProcedureNewCustomField'
      }
    }
  },
  {
    path: 'procedures/:procedureSlug/:stepSlug/:sectionSlug/new-element/:blockSlug',
    name: 'ClientProcedureNewBlock',
    component: ProcedureBlock,
    props: true,
    meta: {
      requiredAuth: true,
      requiredFeatures: ['donation_forms'],
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.forms', routeName: 'ClientProcedures' },
        { slot: 'Procedure', routeName: 'ClientProcedure' },
        { slot: 'ProcedureStep', routeName: 'ClientProcedureStep' },
        { label: 'literal.add_an_element', routeName: 'ClientProcedureNewBlockChoice' },
        { slot: 'ProcedureBlock', routeName: 'ClientProcedureNewBlock' }
      ],
      navigation: {
        back: 'ClientProcedureNewBlockChoice',
        step: 'ClientProcedureStep'
      }
    }
  },
  {
    path: 'procedures/:procedureSlug/:stepSlug/:sectionSlug/custom/:customFieldId',
    name: 'ClientProcedureNewCustomField',
    component: ProcedureCustomField,
    props: true,
    meta: {
      requiredAuth: true,
      requiredFeatures: ['donation_forms'],
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.forms', routeName: 'ClientProcedures' },
        { slot: 'Procedure', routeName: 'ClientProcedure' },
        { slot: 'ProcedureStep', routeName: 'ClientProcedureStep' },
        { label: 'literal.add_an_element', routeName: 'ClientProcedureNewBlockChoice' },
        { slot: 'ProcedureCustomField', routeName: 'ClientProcedureNewCustomField' }
      ],
      navigation: {
        back: 'ClientProcedureNewBlockChoice',
        step: 'ClientProcedureStep'
      }
    }
  },
  {
    path: 'procedures/:procedureSlug/:stepSlug/:sectionSlug/:blockSlug',
    name: 'ClientProcedureEditBlock',
    component: ProcedureBlock,
    props: true,
    meta: {
      requiredAuth: true,
      requiredFeatures: ['donation_forms'],
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.forms', routeName: 'ClientProcedures' },
        { slot: 'Procedure', routeName: 'ClientProcedure' },
        { slot: 'ProcedureStep', routeName: 'ClientProcedureStep' },
        { slot: 'ProcedureBlock', routeName: 'ClientProcedureEditBlock' }
      ],
      navigation: {
        back: 'ClientProcedureStep',
        step: 'ClientProcedureStep'
      }
    }
  }
]

const routesCampaigns = [
  {
    path: 'campaigns/:campaignSlug/procedures/:procedureSlug',
    name: 'CampaignProcedure',
    component: Procedure,
    props: true,
    meta: {
      requiredAuth: true,
      requiredFeatures: ['campaigns'],
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.campaigns', routeName: 'Campaigns' },
        { label: 'literal.campaign_overview', routeName: 'Campaign' },
        { label: 'literal.forms', routeName: 'CampaignProcedures' },
        { slot: 'Procedure', routeName: 'CampaignProcedure' }
      ],
      navigation: {
        back: 'CampaignProcedures',
        step: 'CampaignProcedureStep'
      },
      faq: ['procedures_how_can_i_build_a_form']
    }
  },
  {
    path: 'campaigns/:campaignSlug/procedures/:procedureSlug/:stepSlug',
    name: 'CampaignProcedureStep',
    component: ProcedureStep,
    props: true,
    meta: {
      requiredAuth: true,
      requiredFeatures: ['campaigns'],
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.campaigns', routeName: 'Campaigns' },
        { label: 'literal.campaign_overview', routeName: 'Campaign' },
        { label: 'literal.forms', routeName: 'CampaignProcedures' },
        { slot: 'Procedure', routeName: 'CampaignProcedure' },
        { slot: 'ProcedureStep', routeName: 'CampaignProcedureStep' }
      ],
      navigation: {
        back: 'CampaignProcedure',
        editStep: 'CampaignProcedureEditStep',
        newSection: 'CampaignProcedureNewSection',
        editBlock: 'CampaignProcedureEditBlock',
        blockChoice: 'CampaignProcedureNewBlockChoice'
      },
      faq: ['procedures_how_can_i_build_a_form']
    }
  },
  {
    path: 'campaigns/:campaignSlug/procedures/:procedureSlug/:stepSlug/new-section',
    name: 'CampaignProcedureNewSection',
    component: ProcedureNewSection,
    props: true,
    meta: {
      requiredAuth: true,
      requiredFeatures: ['campaigns'],
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.campaigns', routeName: 'Campaigns' },
        { label: 'literal.campaign_overview', routeName: 'Campaign' },
        { label: 'literal.forms', routeName: 'CampaignProcedures' },
        { slot: 'Procedure', routeName: 'CampaignProcedure' },
        { slot: 'ProcedureStep', routeName: 'CampaignProcedureStep' },
        { label: 'literal.new_section', routeName: 'CampaignProcedureNewSection' }
      ],
      navigation: {
        back: 'CampaignProcedureStep'
      }
    }
  },
  {
    path: 'campaigns/:campaignSlug/procedures/:procedureSlug/:stepSlug/:sectionSlug/new-element',
    name: 'CampaignProcedureNewBlockChoice',
    component: ProcedureNewBlockChoice,
    props: true,
    meta: {
      requiredAuth: true,
      requiredFeatures: ['campaigns'],
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.campaigns', routeName: 'Campaigns' },
        { label: 'literal.campaign_overview', routeName: 'Campaign' },
        { label: 'literal.forms', routeName: 'CampaignProcedures' },
        { slot: 'Procedure', routeName: 'CampaignProcedure' },
        { slot: 'ProcedureStep', routeName: 'CampaignProcedureStep' },
        { label: 'literal.add_an_element', routeName: 'CampaignProcedureNewBlockChoice' }
      ],
      navigation: {
        back: 'CampaignProcedureStep',
        newBlock: 'CampaignProcedureNewBlock',
        newCustomField: 'CampaignProcedureNewCustomField'
      }
    }
  },
  {
    path: 'campaigns/:campaignSlug/procedures/:procedureSlug/:stepSlug/:sectionSlug/new-element/:blockSlug',
    name: 'CampaignProcedureNewBlock',
    component: ProcedureBlock,
    props: true,
    meta: {
      requiredAuth: true,
      requiredFeatures: ['campaigns'],
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.campaigns', routeName: 'Campaigns' },
        { label: 'literal.campaign_overview', routeName: 'Campaign' },
        { label: 'literal.forms', routeName: 'CampaignProcedures' },
        { slot: 'Procedure', routeName: 'CampaignProcedure' },
        { slot: 'ProcedureStep', routeName: 'CampaignProcedureStep' },
        { label: 'literal.add_an_element', routeName: 'CampaignProcedureNewBlockChoice' },
        { slot: 'ProcedureBlock', routeName: 'CampaignProcedureNewBlock' }
      ],
      navigation: {
        back: 'CampaignProcedureNewBlockChoice',
        step: 'CampaignProcedureStep'
      },
      faq: [
        'procedures_what_is_benefiting',
        'procedures_how_can_i_sell_products_in_my_forms'
      ]
    }
  },
  {
    path: 'campaigns/:campaignSlug/procedures/:procedureSlug/:stepSlug/:sectionSlug/custom/:customFieldId',
    name: 'CampaignProcedureNewCustomField',
    component: ProcedureCustomField,
    props: true,
    meta: {
      requiredAuth: true,
      requiredFeatures: ['campaigns'],
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.campaigns', routeName: 'Campaigns' },
        { label: 'literal.campaign_overview', routeName: 'Campaign' },
        { label: 'literal.forms', routeName: 'CampaignProcedures' },
        { slot: 'Procedure', routeName: 'CampaignProcedure' },
        { slot: 'ProcedureStep', routeName: 'CampaignProcedureStep' },
        { label: 'literal.add_an_element', routeName: 'CampaignProcedureNewBlockChoice' },
        { slot: 'ProcedureCustomField', routeName: 'CampaignProcedureNewCustomField' }
      ],
      navigation: {
        back: 'CampaignProcedureNewBlockChoice',
        step: 'CampaignProcedureStep'
      }
    }
  },
  {
    path: 'campaigns/:campaignSlug/procedures/:procedureSlug/:stepSlug/:sectionSlug/:blockSlug',
    name: 'CampaignProcedureEditBlock',
    component: ProcedureBlock,
    props: true,
    meta: {
      requiredAuth: true,
      requiredFeatures: ['campaigns'],
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.campaigns', routeName: 'Campaigns' },
        { label: 'literal.campaign_overview', routeName: 'Campaign' },
        { label: 'literal.forms', routeName: 'CampaignProcedures' },
        { slot: 'Procedure', routeName: 'CampaignProcedure' },
        { slot: 'ProcedureStep', routeName: 'CampaignProcedureStep' },
        { slot: 'ProcedureBlock', routeName: 'CampaignProcedureEditBlock' }
      ],
      navigation: {
        back: 'CampaignProcedureStep',
        step: 'CampaignProcedureStep'
      },
      faq: ['procedures_what_is_benefiting', 'procedures_how_can_i_sell_products_in_my_forms']
    }
  }
]

const routes = [
  ...routesClient,
  ...routesCampaigns
]

export default routes
