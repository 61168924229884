import { useAuthenticationStore } from '@/stores/AuthenticationStore'
const Login = () => import(/* webpackChunkName: 'ViewLogin' */'@/views/sessions/ViewLogin')
const Register = () => import(/* webpackChunkName: 'ViewRegister' */'@/views/sessions/ViewRegister')
const ForgotPassword = () => import(/* webpackChunkName: 'ViewForgotPassword' */'@/views/sessions/ViewForgotPassword')
const ResetPassword = () => import(/* webpackChunkName: 'ViewResetPassword' */'@/views/sessions/ViewResetPassword')

const routes = [
  {
    path: 'login',
    name: 'Login',
    component: Login,
    meta: {
      requiredGuest: true,
      breadcrumb: [
        { label: 'literal.login', routeName: 'Login' }
      ]
    }
  },
  {
    path: 'register',
    name: 'Register',
    component: Register,
    meta: {
      requiredGuest: true,
      breadcrumb: [
        { label: 'literal.register', routeName: 'Register' }
      ]
    },
    beforeEnter (to, from, next) {
      if (Object.keys(to.query).length === 0) {
        next({ name: 'Login' })
      } else {
        next()
      }
    }
  },
  {
    path: 'forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      requiredGuest: true,
      breadcrumb: [
        { label: 'literal.forgot_password', routeName: 'ForgotPassword' }
      ]
    }
  },
  {
    path: 'reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      requiredGuest: true,
      breadcrumb: [
        { label: 'literal.new_password', routeName: 'ResetPassword' }
      ]
    },
    beforeEnter (to, from, next) {
      if (Object.keys(to.query).length === 0) {
        next({ name: 'Login' })
      } else {
        next()
      }
    }
  },
  {
    path: 'logout',
    name: 'Logout',
    meta: { requiredAuth: true },
    beforeEnter (to, from, next) {
      const { logout } = useAuthenticationStore()
      logout()
      next({ name: 'Login' })
    }
  }
]

export default routes
