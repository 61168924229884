import { createRouter, createWebHistory, RouterView } from 'vue-router'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'
import { useGlobalStore } from '@/stores/GlobalStore'
import { useLanguageStore } from '@/stores/LanguageStore'
import { useAllowedFeatures } from '@/composables/app/useAllowedFeatures'
import { useRoles } from '@/composables/app/useRoles'
import { storeToRefs } from 'pinia'
import Routes from '@/routes/routes'
import { uniqBy as _uniqBy } from 'lodash'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/:locale',
      component: RouterView,
      children: Routes,
      beforeEnter: (to, from, next) => {
        const { locale } = storeToRefs(useLanguageStore())
        const path = to.path === '/' ? to.path + locale.value + '/' : to.path.replace(to.params.locale, locale.value)

        to.params.locale !== locale.value ? next({ path }) : next()
      }
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: () => {
        const { locale } = storeToRefs(useLanguageStore())
        return '/' + locale.value
      }
    }
  ],
  scrollBehavior (to, from) {
    if (to.path === from.path && to.query !== from.query) {
      return { left: window.scrollX, top: window.scrollY }
    } else {
      return { left: 0, top: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  const { initAuthentication } = useAuthenticationStore()

  initAuthentication()
    .then(user => {
      const { locale } = storeToRefs(useLanguageStore())
      const { isAuthorized } = useRoles()
      const { isAllowed } = useAllowedFeatures()

      const required_auth = to.matched.some(route => route.meta.requiredAuth)
      const required_guest = to.matched.some(route => route.meta.requiredGuest)
      const required_roles = _uniqBy(to.matched.reduce((roles, { meta }) => meta.requiredRoles ? [...roles, ...meta.requiredRoles] : roles, []))
      const required_features = _uniqBy(to.matched.reduce((features, { meta }) => meta.requiredFeatures ? [...features, ...meta.requiredFeatures] : features, []))

      if (required_auth && !user)
        return next({ name: 'Login', params: { locale: locale.value, ...to.params }, query: { redirectTo: to.path } })

      if (required_guest && user)
        return next({ name: 'Home', params: { locale: locale.value } })

      if (required_roles.length && !required_roles.some(role => isAuthorized(role)))
        return next({ name: 'NotFound', params: { locale: locale.value } })

      if (required_features.length && !required_features.every(feature => isAllowed(feature)))
        return next({ name: 'NotFound', params: { locale: locale.value } })

      next()
    })
})


router.onError(error => {
  const globalStore = useGlobalStore()

  if (/ChunkLoadError:.*failed./i.test(error.message) || /Loading.*chunk.*failed./i.test(error.message)) {
    globalStore.$patch({ is_router_error: true })
  }
})

export default router
