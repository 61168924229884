const Settings = () => import(/* webpackChunkName: 'Settings' */'@/views/settings/ViewSettings')
const SettingsGeneral = () => import(/* webpackChunkName: 'SettingsGeneral' */'@/views/settings/general/ViewSettingsGeneral')
const SettingsFinancials = () => import(/* webpackChunkName: 'SettingsFinancials' */'@/views/settings/financials/ViewSettingsFinancials')
const StripeNewBankAccount = () => import(/* webpackChunkName: 'StripeNewBankAccount' */'@/views/settings/financials/ViewStripeNewBankAccount')
const SettingsBranding = () => import(/* webpackChunkName: 'SettingsBranding' */'@/views/settings/branding/ViewSettingsBranding')
const SettingsBrandingColors = () => import(/* webpackChunkName: 'SettingsBrandingColors' */'@/views/settings/branding/ViewSettingsBrandingColors')
const SettingsBrandingTypography = () => import(/* webpackChunkName: 'SettingsBrandingTypography' */'@/views/settings/branding/ViewSettingsBrandingTypography')
const SettingsBrandingLogo = () => import(/* webpackChunkName: 'SettingsBrandingLogo' */'@/views/settings/branding/ViewSettingsBrandingLogo')
const SettingsPeopleAndRoles = () => import(/* webpackChunkName: 'SettingsPeopleAndRoles' */'@/views/settings/people-and-roles/ViewSettingsPeopleAndRoles')
const SettingsPeopleAndRolesRolesOverview = () => import(/* webpackChunkName: 'SettingsPeopleAndRolesRolesOverview' */'@/views/settings/people-and-roles/ViewSettingsPeopleAndRolesRolesOverview')
const SettingsPeopleAndRolesSendInvites = () => import(/* webpackChunkName: 'SettingsPeopleAndRolesSendInvites' */'@/views/settings/people-and-roles/ViewSettingsPeopleAndRolesSendInvites')
const SettingsPeopleAndRolesUser = () => import(/* webpackChunkName: 'SettingsPeopleAndRolesUser' */'@/views/settings/people-and-roles/ViewSettingsPeopleAndRolesUser')
const SettingsPeopleAndRolesInvite = () => import(/* webpackChunkName: 'SettingsPeopleAndRolesInvite' */'@/views/settings/people-and-roles/ViewSettingsPeopleAndRolesInvite')
const SettingsIntegrations = () => import(/* webpackChunkName: 'SettingsIntegrations' */'@/views/settings/integrations/ViewSettingsIntegrations')
const SettingsDomains = () => import(/* webpackChunkName: 'SettingsDomains' */'@/views/settings/domains/ViewSettingsDomains')
const SettingsCustomFields = () => import(/* webpackChunkName: 'SettingsCustomFields' */'@/views/settings/custom-fields/ViewSettingsCustomFields')
const SettingsNewCustomField = () => import(/* webpackChunkName: 'SettingsNewCustomField' */'@/views/settings/custom-fields/ViewSettingsNewCustomField')
const SettingsEditCustomField = () => import(/* webpackChunkName: 'SettingsEditCustomField' */'@/views/settings/custom-fields/ViewSettingsEditCustomField')

const routes = [
  {
    path: 'settings',
    name: 'Settings',
    component: Settings,
    meta: {
      requiredAuth: true,
      requiredRoles: ['manager', 'accountant'],
      breadcrumb: [
        { label: 'literal.settings', routeName: 'Settings' }
      ]
    }
  },
  {
    path: 'settings/general',
    name: 'SettingsGeneral',
    component: SettingsGeneral,
    meta: {
      requiredAuth: true,
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.settings', routeName: 'Settings' },
        { label: 'literal.general', routeName: 'SettingsGeneral' }
      ]
    }
  },
  {
    path: 'settings/financials',
    name: 'SettingsFinancials',
    component: SettingsFinancials,
    meta: {
      requiredAuth: true,
      requiredRoles: ['accountant'],
      breadcrumb: [
        { label: 'literal.settings', routeName: 'Settings' },
        { label: 'literal.financials', routeName: 'SettingsFinancials' }
      ],
      faq: [
        'settings_what_is_a_payment_provider_account_and_why_do_you_need_to_create_one_under_koalect',
        'settings_can_i_link_different_bank_accounts_in_order_to_be_able_to_choose_on_which_bank_account_the_transactions_are_paid'
      ]
    }
  },
  {
    path: 'settings/financials/stripe/new-bank-account',
    name: 'StripeNewBankAccount',
    component: StripeNewBankAccount,
    meta: {
      requiredAuth: true,
      requiredRoles: ['accountant'],
      breadcrumb: [
        { label: 'literal.settings', routeName: 'Settings' },
        { label: 'literal.financials', routeName: 'SettingsFinancials' },
        { label: 'literal.new_bank_account', routeName: 'StripeNewBankAccount' }
      ]
    }
  },
  {
    path: 'settings/branding',
    name: 'SettingsBranding',
    component: SettingsBranding,
    meta: {
      requiredAuth: true,
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.settings', routeName: 'Settings' },
        { label: 'literal.branding', routeName: 'SettingsBranding' }
      ],
      faq: [
        'settings_what_can_i_upload_to_match_my_branding',
        'settings_can_i_upload_my_own_font'
      ]
    }
  },
  {
    path: 'settings/branding/colors',
    name: 'SettingsBrandingColors',
    redirect: { name: 'SettingsBrandingColorsGeneral' }
  },
  {
    path: 'settings/branding/colors/general',
    name: 'SettingsBrandingColorsGeneral',
    component: SettingsBrandingColors,
    meta: {
      requiredAuth: true,
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.settings', routeName: 'Settings' },
        { label: 'literal.branding', routeName: 'SettingsBranding' },
        { label: 'literal.general_colors', routeName: 'SettingsBrandingColorsGeneral' }
      ],
      faq: ['settings_what_can_i_upload_to_match_my_branding']
    }
  },
  {
    path: 'settings/branding/colors/text-buttons',
    name: 'SettingsBrandingColorsTextButtons',
    component: SettingsBrandingColors,
    meta: {
      requiredAuth: true,
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.settings', routeName: 'Settings' },
        { label: 'literal.branding', routeName: 'SettingsBranding' },
        { label: 'literal.text_color_on_buttons', routeName: 'SettingsBrandingColorsTextButtons' }
      ],
      faq: ['settings_what_can_i_upload_to_match_my_branding']
    }
  },
  {
    path: 'settings/branding/typography',
    name: 'SettingsBrandingTypography',
    component: SettingsBrandingTypography,
    meta: {
      requiredAuth: true,
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.settings', routeName: 'Settings' },
        { label: 'literal.branding', routeName: 'SettingsBranding' },
        { label: 'literal.typography', routeName: 'SettingsBrandingTypography' }
      ],
      faq: [
        'settings_what_can_i_upload_to_match_my_branding',
        'settings_can_i_upload_my_own_font'
      ]
    }
  },
  {
    path: 'settings/branding/logo',
    name: 'SettingsBrandingLogo',
    component: SettingsBrandingLogo,
    meta: {
      requiredAuth: true,
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.settings', routeName: 'Settings' },
        { label: 'literal.branding', routeName: 'SettingsBranding' },
        { label: 'literal.logo', routeName: 'SettingsBrandingLogo' }
      ],
      faq: ['settings_what_can_i_upload_to_match_my_branding']
    }
  },
  {
    path: 'settings/people-and-roles',
    name: 'SettingsPeopleAndRoles',
    component: SettingsPeopleAndRoles,
    meta: {
      requiredAuth: true,
      requiredRoles: ['admin'],
      breadcrumb: [
        { label: 'literal.settings', routeName: 'Settings' },
        { label: 'literal.users_and_roles', routeName: 'SettingsPeopleAndRoles' }
      ],
      faq: ['settings_how_can_i_give_my_colleague_access_to_mykoalect']
    }
  },
  {
    path: 'settings/people-and-roles/roles-overview',
    name: 'SettingsPeopleAndRolesRolesOverview',
    component: SettingsPeopleAndRolesRolesOverview,
    meta: {
      requiredAuth: true,
      requiredRoles: ['admin'],
      breadcrumb: [
        { label: 'literal.settings', routeName: 'Settings' },
        { label: 'literal.users_and_roles', routeName: 'SettingsPeopleAndRoles' },
        { label: 'literal.roles', routeName: 'SettingsPeopleAndRolesRolesOverview' }
      ]
    }
  },
  {
    path: 'settings/people-and-roles/send-invites',
    name: 'SettingsPeopleAndRolesSendInvites',
    component: SettingsPeopleAndRolesSendInvites,
    meta: {
      requiredAuth: true,
      requiredRoles: ['admin'],
      breadcrumb: [
        { label: 'literal.settings', routeName: 'Settings' },
        { label: 'literal.users_and_roles', routeName: 'SettingsPeopleAndRoles' },
        { label: 'literal.send_invites', routeName: 'SettingsPeopleAndRolesSendInvites' }
      ]
    }
  },
  {
    path: 'settings/people-and-roles/user/:id',
    name: 'SettingsPeopleAndRolesUser',
    component: SettingsPeopleAndRolesUser,
    props: true,
    meta: {
      requiredAuth: true,
      requiredRoles: ['admin'],
      breadcrumb: [
        { label: 'literal.settings', routeName: 'Settings' },
        { label: 'literal.users_and_roles', routeName: 'SettingsPeopleAndRoles' },
        { routeName: 'SettingsPeopleAndRolesUser' }
      ]
    }
  },
  {
    path: 'settings/people-and-roles/invite/:id',
    name: 'SettingsPeopleAndRolesInvite',
    component: SettingsPeopleAndRolesInvite,
    props: true,
    meta: {
      requiredAuth: true,
      requiredRoles: ['admin'],
      breadcrumb: [
        { label: 'literal.settings', routeName: 'Settings' },
        { label: 'literal.users_and_roles', routeName: 'SettingsPeopleAndRoles' },
        { routeName: 'SettingsPeopleAndRolesInvite' }
      ]
    }
  },
  {
    path: 'settings/integrations',
    name: 'SettingsIntegrations',
    component: SettingsIntegrations,
    meta: {
      requiredAuth: true,
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.settings', routeName: 'Settings' },
        { label: 'literal.integrations', routeName: 'SettingsIntegrations' }
      ],
      faq: [
        'settings_what_is_google_tag_manager',
        'settings_what_is_a_facebook_app',
        'settings_what_is_google_search_console'
      ]
    }
  },
  {
    path: 'settings/domains',
    name: 'SettingsDomains',
    component: SettingsDomains,
    meta: {
      requiredAuth: true,
      requiredRoles: ['admin'],
      breadcrumb: [
        { label: 'literal.settings', routeName: 'Settings' },
        { label: 'literal.domains', routeName: 'SettingsDomains' }
      ],
      faq: ['settings_how_to_add_a_custom_domain']
    }
  },
  {
    path: 'settings/custom-fields',
    name: 'SettingsCustomFields',
    component: SettingsCustomFields,
    meta: {
      requiredAuth: true,
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.settings', routeName: 'Settings' },
        { label: 'literal.custom_fields', routeName: 'SettingsCustomFields' }
      ]
    }
  },
  {
    path: 'settings/custom-fields/new',
    name: 'SettingsNewCustomField',
    component: SettingsNewCustomField,
    meta: {
      requiredAuth: true,
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.settings', routeName: 'Settings' },
        { label: 'literal.custom_fields', routeName: 'SettingsCustomFields' },
        { label: 'literal.create_new_custom_field', routeName: 'SettingsNewCustomField' }
      ]
    }
  },
  {
    path: 'settings/custom-fields/:id',
    name: 'SettingsEditCustomField',
    component: SettingsEditCustomField,
    props: true,
    meta: {
      requiredAuth: true,
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.settings', routeName: 'Settings' },
        { label: 'literal.custom_fields', routeName: 'SettingsCustomFields' },
        { slot: 'CustomField', routeName: 'SettingsEditCustomField' }
      ]
    }
  }
]

export default routes
