import { usePageStore } from '@/stores/PageStore'
import { whenever } from '@/utils/utils'

const Pages = () => import(/* webpackChunkName: 'Pages' */'@/views/pages/ViewPages')
const NewPage = () => import(/* webpackChunkName: 'NewPage' */'@/views/pages/ViewNewPage')
const PagePartial = () => import(/* webpackChunkName: 'PagePartial' */'@/views/pages/ViewPagePartial')
const PageLayout = () => import(/* webpackChunkName: 'PageLayout' */'@/views/pages/ViewPageLayout')

const routes = [
  {
    path: 'pages',
    name: 'Pages',
    component: Pages,
    meta: {
      requiredAuth: true,
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.pages', routeName: 'Pages' }
      ],
      faq: [
        'pages_what_are_the_system_pages',
        'pages_how_can_i_create_a_new_landing_or_homepage',
        'pages_how_can_i_link_a_page_to_my_header_or_footer'
      ]
    }
  },
  {
    path: 'pages/header',
    name: 'PagesHeader',
    component: PageLayout,
    props: true,
    meta: {
      requiredAuth: true,
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.pages', routeName: 'Pages' },
        { label: 'literal.header', routeName: 'PagesHeader' }
      ],
      faq: ['pages_how_can_i_link_a_page_to_my_header_or_footer']
    },
    beforeEnter: (to, from, next) => {
      const { getLayout } = usePageStore()
      const { layout } = getLayout()
      const { locale } = to.params

      whenever(layout, () => layout.value.show_header ? next() : next({ name: 'Pages', params: { locale } }), { immediate: true })
    }
  },
  {
    path: 'pages/footer',
    name: 'PagesFooter',
    component: PageLayout,
    props: true,
    meta: {
      requiredAuth: true,
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.pages', routeName: 'Pages' },
        { label: 'literal.footer', routeName: 'PagesFooter' }
      ],
      faq: ['pages_how_can_i_link_a_page_to_my_header_or_footer']
    },
    beforeEnter: (to, from, next) => {
      const { getLayout } = usePageStore()
      const { layout } = getLayout()
      const { locale } = to.params

      whenever(layout, () => layout.value.show_footer ? next() : next({ name: 'Pages', params: { locale } }), { immediate: true })
    }
  },
  {
    path: 'pages/new',
    name: 'NewPage',
    component: NewPage,
    meta: {
      requiredAuth: true,
      breadcrumb: [
        { label: 'literal.pages', routeName: 'Pages' },
        { label: 'literal.create_new_page', routeName: 'NewPage' }
      ]
    }
  },
  {
    path: 'pages/:id',
    name: 'EditPage',
    component: PagePartial,
    props: true,
    meta: {
      requiredAuth: true,
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.pages', routeName: 'Pages' },
        { routeName: 'EditPage' }
      ],
      faq: [
        'pages_how_can_i_add_campaigns_to_my_campaign_page',
        'pages_how_can_i_create_a_new_landing_or_homepage',
        'pages_elements_for_building_a_custom_page'
      ]
    }
  }
]

export default routes
