import { storeToRefs } from 'pinia'
import { useClientStore } from '@/stores/ClientStore'
import { useLanguageStore } from '@/stores/LanguageStore'
import { useAllowedFeatures } from '@/composables/app/useAllowedFeatures'

const Menu = () => import(/* webpackChunkName: 'Menu' */'@/views/standalone/ViewMenu')
const NotFound = () => import(/* webpackChunkName: 'Menu' */'@/views/standalone/ViewNotFound')

const routes = [
  {
    path: '',
    name: 'Home',
    meta: { requiredAuth: true },
    beforeEnter (to, from, next) {
      const { getClient } = useClientStore()
      const { client } = getClient()
      const { locale } = storeToRefs(useLanguageStore())
      const { isAllowed } = useAllowedFeatures()

      if (!client.value) return next({ name: 'Login', params: { locale: locale.value } })

      if (isAllowed('campaigns')) {
        next({ name: 'Campaigns', params: { locale: locale.value } })
      } else if (isAllowed('donation_forms')) {
        next({ name: 'ClientProcedures', params: { locale: locale.value } })
      } else {
        next({ name: 'Profile', params: { locale: locale.value } })
      }
    }
  },
  {
    path: 'menu',
    name: 'Menu',
    component: Menu,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: 'not-found',
    name: 'NotFound',
    component: NotFound,
    meta: {
      requiredAuth: true
    }
  }
]

export default routes
