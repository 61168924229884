import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useClientStore } from '@/stores/ClientStore'
import { get as _get } from 'lodash'

export function useAllowedFeatures () {
  const { getClient } = useClientStore()
  const { client } = storeToRefs(getClient())

  const allowedDonationForms = computed(() => _get(client, 'value.allowed_features.donation_forms') === true)
  const allowedCampaigns = computed(() => _get(client, 'value.allowed_features.campaigns') === true)
  const allowedBenefitings = computed(() => _get(client, 'value.allowed_features.benefitings') === true)
  const allowedProducts = computed(() => _get(client, 'value.allowed_features.products') === true)
  const allowedTwikey = computed(() => _get(client, 'value.integrations.twikey.api_secret_key', false))
  const providers = computed(() => _get(client, 'value.allowed_features.sporting_activities.providers', false))
  const allowedSportingActivities = computed(() => providers.value === true || (Array.isArray(providers.value)) && providers.value.length > 0)

  const allowedSportingActivitiesProvider = provider => providers.value === true || (Array.isArray(providers.value) && providers.value.includes(provider))

  const isAllowed = name => {
    if (name === 'donation_forms') return allowedDonationForms.value
    if (name === 'campaigns') return allowedCampaigns.value
    if (name === 'benefitings') return allowedBenefitings.value
    if (name === 'products') return allowedProducts.value
    if (name === 'twikey') return allowedTwikey.value

    return false
  }

  return {
    allowedDonationForms,
    allowedCampaigns,
    allowedBenefitings,
    allowedProducts,
    allowedTwikey,
    allowedSportingActivities,
    allowedSportingActivitiesProvider,
    isAllowed
  }
}
