const Profile = () => import(/* webpackChunkName: 'Profile' */'@/views/profile/ViewProfile')
const ProfileSettings = () => import(/* webpackChunkName: 'ProfileSettings' */'@/views/profile/ViewProfileSettings')
const ProfileSettingsFirstname = () => import(/* webpackChunkName: 'ProfileSettingsFirstname' */'@/views/profile/ViewProfileSettingsFirstname')
const ProfileSettingsLastname = () => import(/* webpackChunkName: 'ProfileSettingsLastname' */'@/views/profile/ViewProfileSettingsLastname')
const ProfileSettingsEmail = () => import(/* webpackChunkName: 'ProfileSettingsEmail' */'@/views/profile/ViewProfileSettingsEmail')
const ProfileSettingsPassword = () => import(/* webpackChunkName: 'ProfileSettingsPassword' */'@/views/profile/ViewProfileSettingsPassword')
const ProfileSettingsAvatar = () => import(/* webpackChunkName: 'ProfileSettingsAvatar' */'@/views/profile/ViewProfileSettingsAvatar')
const ProfileSettingsBirthday = () => import(/* webpackChunkName: 'ProfileSettingsBirthday' */'@/views/profile/ViewProfileSettingsBirthday')
const ProfileSettingsLanguage = () => import(/* webpackChunkName: 'ProfileSettingsLanguage' */'@/views/profile/ViewProfileSettingsLanguage')

const routes = [
  {
    path: 'profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiredAuth: true,
      breadcrumb: [
        { label: 'literal.my_profile', routeName: 'Profile' }
      ]
    }
  },
  {
    path: 'profile/settings',
    name: 'ProfileSettings',
    component: ProfileSettings,
    meta: {
      requiredAuth: true,
      breadcrumb: [
        { label: 'literal.my_profile', routeName: 'Profile' },
        { label: 'literal.settings', routeName: 'ProfileSettings' }
      ]
    }
  },
  {
    path: 'profile/settings/firstname',
    name: 'ProfileSettingsFirstname',
    component: ProfileSettingsFirstname,
    meta: {
      requiredAuth: true,
      breadcrumb: [
        { label: 'literal.my_profile', routeName: 'Profile' },
        { label: 'literal.settings', routeName: 'ProfileSettings' },
        { label: 'literal.firstname', routeName: 'ProfileSettingsFirstname' }
      ]
    }
  },
  {
    path: 'profile/settings/lastname',
    name: 'ProfileSettingsLastname',
    component: ProfileSettingsLastname,
    meta: {
      requiredAuth: true,
      breadcrumb: [
        { label: 'literal.my_profile', routeName: 'Profile' },
        { label: 'literal.settings', routeName: 'ProfileSettings' },
        { label: 'literal.lastname', routeName: 'ProfileSettingsLastname' }
      ]
    }
  },
  {
    path: 'profile/settings/email',
    name: 'ProfileSettingsEmail',
    component: ProfileSettingsEmail,
    meta: {
      requiredAuth: true,
      breadcrumb: [
        { label: 'literal.my_profile', routeName: 'Profile' },
        { label: 'literal.settings', routeName: 'ProfileSettings' },
        { label: 'literal.email', routeName: 'ProfileSettingsEmail' }
      ]
    }
  },
  {
    path: 'profile/settings/password',
    name: 'ProfileSettingsPassword',
    component: ProfileSettingsPassword,
    meta: {
      requiredAuth: true,
      breadcrumb: [
        { label: 'literal.my_profile', routeName: 'Profile' },
        { label: 'literal.settings', routeName: 'ProfileSettings' },
        { label: 'literal.password', routeName: 'ProfileSettingsPassword' }
      ]
    }
  },
  {
    path: 'profile/settings/avatar',
    name: 'ProfileSettingsAvatar',
    component: ProfileSettingsAvatar,
    meta: {
      requiredAuth: true,
      breadcrumb: [
        { label: 'literal.my_profile', routeName: 'Profile' },
        { label: 'literal.settings', routeName: 'ProfileSettings' },
        { label: 'literal.avatar', routeName: 'ProfileSettingsAvatar' }
      ]
    }
  },
  {
    path: 'profile/settings/birthday',
    name: 'ProfileSettingsBirthday',
    component: ProfileSettingsBirthday,
    meta: {
      requiredAuth: true,
      breadcrumb: [
        { label: 'literal.my_profile', routeName: 'Profile' },
        { label: 'literal.settings', routeName: 'ProfileSettings' },
        { label: 'literal.date_of_birth', routeName: 'ProfileSettingsBirthday' }
      ]
    }
  },
  {
    path: 'profile/settings/language',
    name: 'ProfileSettingsLanguage',
    component: ProfileSettingsLanguage,
    meta: {
      requiredAuth: true,
      breadcrumb: [
        { label: 'literal.my_profile', routeName: 'Profile' },
        { label: 'literal.settings', routeName: 'ProfileSettings' },
        { label: 'literal.language', routeName: 'ProfileSettingsLanguage' }
      ]
    }
  }
]

export default routes
