const Qrcodes = () => import(/* webpackChunkName: 'Qrcodes' */'@/views/qrcodes/ViewQrcodes')
const NewQrcode = () => import(/* webpackChunkName: 'NewQrcode' */'@/views/qrcodes/ViewNewQrcode')
const Qrcode = () => import(/* webpackChunkName: 'Qrcode' */'@/views/qrcodes/ViewQrcode')

export default [
  {
    path: 'qrcodes',
    name: 'Qrcodes',
    component: Qrcodes,
    meta: {
      requiredAuth: true,
      requiredFeatures: ['twikey'],
      breadcrumb: [
        { label: 'literal.qr_codes', routeName: 'Qrcodes' }
      ],
      faq: [
        'introduction_to_qr_codes',
        'what_kind_of_qr_codes_are_there',
        'create_qr_codes_with_koalect_scan'
      ]
    }
  },
  {
    path: 'qrcodes/new',
    name: 'NewQrcode',
    component: NewQrcode,
    meta: {
      requiredAuth: true,
      requiredRoles: ['manager'],
      requiredFeatures: ['twikey'],
      breadcrumb: [
        { label: 'literal.qr_codes', routeName: 'Qrcodes' },
        { label: 'literal.create_new_qr_code', routeName: 'NewQrcode' },
      ]
    }
  },
  {
    path: 'qrcode/:slug',
    name: 'Qrcode',
    component: Qrcode,
    props: true,
    meta: {
      requiredAuth: true,
      requiredRoles: ['manager'],
      requiredFeatures: ['twikey'],
      breadcrumb: [
        { label: 'literal.qr_codes', routeName: 'Qrcodes' },
        { routeName: 'Qrcode' }
      ],
      navigation: {
        email: 'QrcodeEditEmail'
      }
    }
  }
]
