const Benefitings = () => import(/* webpackChunkName: 'Benefitings' */'@/views/benefitings/ViewBenefitings')
const Benefiting = () => import(/* webpackChunkName: 'Benefitings' */'@/views/benefitings/ViewBenefiting')
const NewBenefiting = () => import(/* webpackChunkName: 'Benefitings' */'@/views/benefitings/ViewNewBenefiting')

export default [
  {
    path: 'benefitings',
    name: 'Benefitings',
    component: Benefitings,
    meta: {
      requiredAuth: true,
      requiredFeatures: ['benefitings'],
      faq: [
        'benefitings_what_is_benefiting',
        'benefitings_how_to_add_new_beneficiaries',
        'benefitings_how_to_integrate_beneficiaries_in_my_campaign'
      ],
      breadcrumb: [
        { label: 'literal.beneficiaries', routeName: 'Benefitings' }
      ]
    }
  },
  {
    path: 'benefitings/:id',
    name: 'Benefiting',
    component: Benefiting,
    props: true,
    meta: {
      requiredAuth: true,
      requiredFeatures: ['benefitings'],
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.beneficiaries', routeName: 'Benefitings' },
        { routeName: 'Benefiting' }
      ]
    }
  },
  {
    path: 'benefitings/new',
    name: 'NewBenefiting',
    component: NewBenefiting,
    meta: {
      requiredAuth: true,
      requiredFeatures: ['benefitings'],
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.beneficiaries', routeName: 'Benefitings' },
        { label: 'literal.new_benefiting', routeName: 'NewBenefiting' }
      ]
    }
  }
]
