const EditProduct = () => import(/* webpackChunkName: 'EditEmail' */'@/views/products/ViewEditProduct')

export default [
  {
    path: 'campaigns/:campaignSlug/products/:id',
    name: 'CampaignEditProduct',
    component: EditProduct,
    props: true,
    meta: {
      requiredAuth: true,
      requiredFeatures: ['campaigns', 'products'],
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.campaigns', routeName: 'Campaigns' },
        { label: 'literal.campaign_overview', routeName: 'Campaign' },
        { label: 'literal.products', routeName: 'CampaignProducts' },
        { slot: 'EditProduct', routeName: 'CampaignEditProduct' }
      ],
      navigation: {
        back: 'CampaignProducts'
      }
    }
  },
  {
    path: 'procedures/:procedureSlug/products/:id',
    name: 'ClientProcedureEditProduct',
    component: EditProduct,
    props: true,
    meta: {
      requiredAuth: true,
      requiredFeatures: ['donation_forms', 'products'],
      requiredRoles: ['manager'],
      breadcrumb: [
        { label: 'literal.forms', routeName: 'ClientProcedures' },
        { label: 'literal.products', routeName: 'ClientProcedureProducts' },
        { slot: 'EditProduct', routeName: 'ClientProcedureEditProduct' }
      ],
      navigation: {
        back: 'ClientProcedureProducts'
      }
    }
  }
]
