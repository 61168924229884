import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'
import { get as _get } from 'lodash'

export function useRoles () {
  const { authUser } = storeToRefs(useAuthenticationStore())

  const roles = {
    moderator: ['moderator', 'admin', 'koalect'],
    accountant: ['accountant', 'admin', 'koalect'],
    manager: ['manager', 'admin', 'koalect'],
    admin: ['admin', 'koalect'],
    koalect: ['koalect']
  }

  const authRole = computed(() => _get(authUser.value, 'role'))
  const isManager = computed(() => roles.manager.includes(authRole.value))
  const isAccountant = computed(() => roles.accountant.includes(authRole.value))
  const isAdmin = computed(() => roles.admin.includes(authRole.value))
  const isKoalect = computed(() => roles.koalect.includes(authRole.value))

  const isAuthorized = role => _get(roles, role, []).includes(authRole.value)

  return {
    roles,
    authRole,
    isManager,
    isAccountant,
    isAdmin,
    isKoalect,
    isAuthorized
  }
}
