const Users = () => import(/* webpackChunkName: 'Users' */'@/views/users/ViewUsers')
const UserDetails = () => import(/* webpackChunkName: 'UserDetails' */'@/views/users/ViewUserDetails')

const routes = [
  {
    path: 'users',
    name: 'Users',
    component: Users,
    meta: {
      requiredAuth: true,
      breadcrumb: [
        { label: 'literal.users', routeName: 'Users' }
      ]
    }
  },
  {
    path: 'users/:id',
    name: 'UserDetails',
    component: UserDetails,
    props: true,
    meta: {
      requiredAuth: true,
      requiredRoles: ['accountant'],
      breadcrumb: [
        { label: 'literal.users', routeName: 'Users' },
        { routeName: 'UserDetails' }
      ]
    }
  }
]

export default routes
