const Data = () => import(/* webpackChunkName: 'Data' */'@/views/data/ViewData')
const SingleData = () => import(/* webpackChunkName: 'SingleData' */'@/views/data/ViewSingleData')

const routes = [
  {
    path: 'data',
    name: 'Data',
    component: Data,
    meta: {
      requiredAuth: true,
      breadcrumb: [
        { label: 'literal.data', routeName: 'Data' }
      ],
      faq: ['data_how_can_i_export_data']
    }
  },
  {
    path: 'data/:id',
    name: 'SingleData',
    component: SingleData,
    props: true,
    meta: {
      requiredAuth: true,
      requiredRoles: ['accountant'],
      breadcrumb: [
        { label: 'literal.data', routeName: 'Data' },
        { routeName: 'SingleData' }
      ]
    }
  }
]

export default routes
